import { AwsGenerationOutput, AwsResourceConfiguration, ConstructType, OutputFormat } from '@amzn/alkimia-model';

export const initialStateAwsResourceConfiguration: AwsResourceConfiguration[] = [
  {
    resourceKey: '',
    sourceType: 'Config',
    configuration: {},
    ids: {},
    resourceType: '',
    sourceAccountId: '',
    sourceRegion: '',
    supportedConstructType: 'L1',
    relevant: true,
    downStreamDependencies: [],
    upStreamDependencies: [],
  },
];

export const initialStateAwsGenerationOutput: AwsGenerationOutput = {
  awsAccountId: '',
  awsRegion: '',
  targetAwsAccountID: '',
  targetAwsRegion: '',
  cdkConstructType: ConstructType.DEFAULT,
  outputFormat: OutputFormat.JSON,
  outputFiles: [],
  messages: [],
};
