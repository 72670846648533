import { DiscoverAwsResourceConfigurationOutput, ScanOperations } from '@amzn/alkimia-model';
import {
  API_RETRY_INTERVAL_IN_MS,
  API_TIMEOUT_INTERVAL_IN_MS,
  constructQueryString,
  enableMockResponses,
  getLambdaApiUrl,
  getStandardHeader,
  mockScanAwsAccountResponse,
  processApiError,
  SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS,
} from '@amzn/alkimia-react-components';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios from 'axios';

const mock = enableMockResponses;

export const getScan = async (
  token: CognitoToken,
  customerAccountId: string | undefined,
  customerAccountRegion: string,
  scanOperation: ScanOperations,
): Promise<DiscoverAwsResourceConfigurationOutput> => {
  if (!customerAccountId) throw new Error('Missing required input - awsAccountId');
  if (mock) { return Promise.resolve(mockScanAwsAccountResponse); }

  return new Promise((resolve) => {
    const startTime = Date.now();
    let currentScanOperation = scanOperation;

    const intervalRetry = setInterval(() => {
      axios.get(`${getLambdaApiUrl()}/datastore/scanAwsAccount?${constructQueryString({
        customerAccountId,
        customerAccountRegion,
        scanOperation: currentScanOperation,
      })}`, {
        headers: getStandardHeader(token),
        timeout: API_TIMEOUT_INTERVAL_IN_MS,
      })
        .then((result) => {
          const { accData, accScanStatus } = result.data;

          if (result.status === 200) {
            clearInterval(intervalRetry);
            resolve({ messages: [], services: JSON.parse(accData), mergedServices: [], accScanStatus });
          }

          if (Date.now() - startTime > SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS * 1000) {
            clearInterval(intervalRetry);
            resolve({
              messages: [`Timed out after ${SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS} seconds`],
              services: JSON.parse(accData) ?? [],
              mergedServices: [],
              accScanStatus,
            });
          }

          if (currentScanOperation === ScanOperations.startScan) {
            currentScanOperation = ScanOperations.getScan;
          }

          if (result.status === 202 && scanOperation === ScanOperations.getScan) {
            clearInterval(intervalRetry);
            resolve({ messages: [], services: [], mergedServices: [], accScanStatus });
          }

          if (result.status === 404) {
            clearInterval(intervalRetry);
            resolve({
              messages: [],
              services: JSON.parse(accData),
              mergedServices: [],
            });
          }
        }).catch((error) => {
          if (error && error.response) {
            const { status } = error.response;
            const { message, accScanStatus } = error.response.data || {};

            if (scanOperation === ScanOperations.getScan) {
              clearInterval(intervalRetry);
              if (status === 403) {
                resolve({ messages: [message], services: [], mergedServices: [] });
              } else if (status === 404) {
                resolve({
                  messages: [message],
                  services: [],
                  mergedServices: [],
                  accScanStatus,
                });
              } else {
                resolve(processApiError('scanAwsAccount', error));
              }
            } else if (status === 403) {
              clearInterval(intervalRetry);
              resolve({ messages: [message], services: [], mergedServices: [], accScanStatus });
            } else {
              resolve(processApiError('scanAwsAccount', error));
            }
          } else {
            clearInterval(intervalRetry);
            resolve(processApiError('scanAwsAccount', error));
          }
        });
    }, API_RETRY_INTERVAL_IN_MS);
  });
};
